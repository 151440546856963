import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to track menu visibility

  useEffect(() => {
    const header = document.getElementById('header');

    const handleScroll = () => {
      if (window.scrollY > 50) {
        header.style.backgroundColor = 'white'; // Change header background to white
        header.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)'; // Optional: Add subtle shadow for better visibility
      } else {
        header.style.backgroundColor = 'white'; // Set back to transparent when not scrolling
        header.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)'; // Remove shadow when not scrolling
      }
    };

    // Set initial background color
    header.style.backgroundColor = 'white'; 

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu open/close state
  };

  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <header className='section w-full fixed top-0 z-50 transition-all duration-300 md:px-[0px] px-[5px] md:px-[20px]' id="header">
        <div className='border-b border-gray-200 lg:border-0 flex justify-between px-8 md:px-4 font-sans min-h-[68px] tracking-wide relative w-full'>
          <div className='container m-auto flex justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            {/* Column 1: Logo */}
            <div className='flex items-center'>
              <NavLink to="/">
                <img src="/images/logo.svg" alt="logo" className='w-28 md:w-[125px] max-lg:mr-5' onContextMenu={disableContextMenu} />
              </NavLink>
            </div>

            {/* Column 2: Menu (hidden on mobile, visible on desktop) */}
            <div className='hidden lg:flex items-center'>
              <ul className='flex gap-2 font-[inter]'>
                <li>
                  <NavLink to='/about-us' className='font-[500] text-[13px]'>About</NavLink>
                </li>
                <li>
                  <NavLink to='/partnerships' className='font-[500] text-[13px]'>
                    Partnerships
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/events' className='font-[500] text-[13px]'>
                    Events
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/contact-us' className='font-[500] text-[13px]'>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* Column 3: Button and Hamburger Menu */}
            <div className='flex items-center gap-4'>
              <NavLink to="/coming-soon">
                <button className='hidden lg:block bg-background-secondry text-[#293762] text-[13px] px-4 py-2 rounded font-sans hover:bg-background-primary transition duration-300 font-semibold border border-[#FFC727] hover:text-[#FFC727] hover:bg-transparent hover:border hover:border-[#FFC727]'>
                  Join the Waitlist
                </button>
              </NavLink>

              {/* Hamburger Menu for Mobile */}
              <button onClick={toggleMenu} className='lg:hidden'>
                {menuOpen ? (
                  <RxCross1 className='text-black' size={18} style={{ strokeWidth: '0px' }} />
                ) : (
                  <FaBars className='text-black' size={20} style={{ strokeWidth: '0px' }} />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`transition-all duration-300 ease-in-out bg-white fixed top-80px left-0 w-full h-[100vh] z-[9999] ${
            menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
          style={{
            height: menuOpen ? '100vh' : '0',
            overflow: 'hidden', // Prevents content overflow when closed
          }}
        >
          <ul className='flex flex-col gap-12 p-4 pt-[32px] items-center h-full'>
            <li>
              <NavLink to='/' className='font-semibold text-[15px]' onClick={toggleMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to='/about-us' className='font-semibold text-[15px]' onClick={toggleMenu}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to='/partnerships' className='font-semibold text-[15px]' onClick={toggleMenu}>
                Partnerships
              </NavLink>
            </li>
            <li>
              <NavLink to='/events' className='font-semibold text-[15px]' onClick={toggleMenu}>
                Events
              </NavLink>
            </li>
            <li>
              <NavLink to='/contact-us' className='font-semibold text-[15px]' onClick={toggleMenu}>
                Contact Us
              </NavLink>
            </li>
            <li>
            <NavLink to="/coming-soon" className='hover:bg-transparent mobile-cb'>
                <button className='block lg:hidden bg-background-secondry text-[#293762] text-[15px] px-4 py-2 rounded font-sans hover:text-white hover:bg-background-primary transition duration-300 font-semibold'>
                  Join the Waitlist
                </button>
              </NavLink>
            </li>
          </ul>
          
        </div>
      </header>
    </>
  );
};

export default Header;
