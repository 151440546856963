import React from 'react';
import { NavLink } from 'react-router-dom';
import { useScroll } from './ScrollContext';



const HomeBanner = () => {
    
    const { scrollToSection1, scrollToSection2 } = useScroll();

    return (
        <>
          <section className="w-full bg-cover bg-[70%] bg-banner md:h-custom-height pt-[80px] pb-[96px] md:py-[40px] mt-[68px] px-[20px] md:px-[50px] lg:px-[100px] " >
                <div className="container mx-auto p-4 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 flex items-center pt-[0px] pb-[0px] md:pt-[30px] md:pb-[38px] ">
                    <div className='order-2 md:order-1 max-w-[480px]'>
                        <h1 className='tracking-[-2px] text-white text-[55px] font-sans font-medium leading-[60px] md:leading-[65px] lg:leading-[82px] mb-5 md:text-[60px] lg:text-[75px]'>You Deserve to be Discovered.</h1>
                        <p className='text-white text-[16px] font-[Inter] leading-tight mb-4 md:text-[18px] md:text-[20px] max-w-[270px] md:max-w-[100%] '>High Value Roles for Undervalued Cast and Crew</p>
                        <div className='flex gap-5 mt-2 md:mt-6'>
                        <NavLink to=""> 
                            <button onClick={scrollToSection1} className='text-[12px] md:text-[16px] font-sans font-semibold px-[15px] md:px-[30px] py-[8px] rounded-xl text-[#2A3762] mt-5 bg-background-secondry hover:bg-background-primary transition duration-300 border border-[#FFC727] hover:text-[#FFC727] hover:bg-transparent hover:border hover:border-[#FFC727]' >For the Talent</button>
                        </NavLink>
                        <NavLink to=""> 
                            <button onClick={scrollToSection2} className='text-[12px] md:text-[16px] font-sans font-semibold px-[15px] md:px-[30px] py-[8px] rounded-xl text-[#2A3762] mt-5 bg-background-secondry hover:bg-background-primary transition duration-300 border border-[#FFC727] hover:text-[#FFC727] hover:bg-transparent hover:border hover:border-[#FFC727]' >For the Producers</button>
                        </NavLink>
                        </div>
                        
                    </div>
                    
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBanner;