import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

const Slider = () => {
  // const slides = [
  //   {
  //     image: '/images/mobileslide3.png',
  //     title: 'Step 1',
  //     description: 'Build a unique profile with headshots, reels, and a personal statement. ',
  //   },
  //   {
  //     image: '/images/mobileslide2.png',
  //     title: 'Step 2',
  //     description: 'Browse through hundreds of vetted productions with numerous roles. Find and apply to productions that fit your talents!',
  //   },
  //   {
  //     image: '/images/mobileslide1.png',
  //     title: 'Step 3',
  //     description: 'Track all your active productions! Once accepted, you can communicate with the director and receive exclusive updates',
  //   },
  // ];

  const slides = [
    {
      image: '/images/Screen_1.png',
      title: 'Step 1',
      description: 'Build a unique profile with headshots, reels, and a personal statement. ',
    },
    {
      image: '/images/Screen_2.png',
      title: 'Step 2',
      description: 'Browse through hundreds of vetted productions with numerous roles. Find and apply to productions that fit your talents!',
    },
    {
      image: '/images/Screen_3.png',
      title: 'Step 3',
      description: 'Track all your active productions! Once accepted, you can communicate with the director and receive exclusive updates',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const autoSlideInterval = useRef(null);

  const slideLength = slides.length;

  const startDragging = (pos) => {
    setIsDragging(true);
    setStartPos(pos);
  };

  const stopDragging = (pos) => {
    setIsDragging(false);
    const endPos = pos;
    const dragDistance = startPos - endPos;

    // If dragged more than 50px to the left or right
    if (dragDistance > 50) {
      nextSlide();
    } else if (dragDistance < -50) {
      prevSlide();
    }
  };

  const handleTouchStart = (e) => startDragging(e.touches[0].clientX);
  const handleTouchMove = (e) => e.preventDefault();
  const handleTouchEnd = (e) => stopDragging(e.changedTouches[0].clientX);

  const handleMouseDown = (e) => startDragging(e.clientX);
  const handleMouseMove = (e) => e.preventDefault();
  const handleMouseUp = (e) => stopDragging(e.clientX);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slideLength - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slideLength - 1 : prev - 1));
  };

  // Auto-slide every 3 seconds
  useEffect(() => {
    autoSlideInterval.current = setInterval(() => {
      nextSlide();
    }, 6000);

    return () => clearInterval(autoSlideInterval.current);
  }, []);

  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="relative w-full max-w-[290px] md:max-w-[280px] mx-auto overflow-hidden"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Slider Container */}
      <div
        className="flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="w-full h-full flex-shrink-0 p-1">
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover" onContextMenu={disableContextMenu}
            />
            {/* Title and Description */}
            <div className="p-4 px-0 text-center">
              <p className="text-[18px] font-bold text-[#1A191E]">{slide.title}</p>
              <p className="text-[16px] text-[#1A191E] mt-1">{slide.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Indicator Dots */}
      <div className="flex justify-center mt-4">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`h-[10px] w-[10px] rounded-full mx-1 cursor-pointer ${
              index === currentSlide ? 'bg-background-primary' : 'bg-gray-400'
            }`}
            onClick={() => setCurrentSlide(index)}
          ></div>
        ))}
      </div>
      <div className='flex md:hidden gap-5 justify-center mt-8'>
      <NavLink to="" target='_black'>
          <img src="/images/appstore.svg" alt="App Store" className='w-[194px] max-lg:mr-5 mb-4 md:w-[194px]' />
      </NavLink> 
      <NavLink to="" target='_black' className="mr-2">
          <img src="/images/googleplay.svg" alt="Play store" className='w-[220] max-lg:mr-5 md:w-[220]' />
      </NavLink>
      </div>
    </div>
  );
};

export default Slider;
