import React, { useEffect, useState } from "react";
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer.jsx'; 
import PageBanner from "../components/PageBanner.jsx";
import {allEventsData} from '../components/data/EventsData.jsx';
import { FaArrowRight } from 'react-icons/fa';
import SubscribeSection from '../components/SubscribeSection.jsx';


const EventCard = ({ category, postTime, title, description }) => {
    return (
      <div className="bg-white rounded-lg border-2 border-[#E8E8E8] p-5 pb-8 mb-4 break-inside-avoid" style={{ height: 'auto' }}>
        <p className="text-[12px] font-medium text-gray-400">{category}</p>
        <p className="text-[#1A191E] text-[16px] mb-4 font-[500]">{postTime}</p>
        <h3 className="text-text-primary text-[24px] font-[600] mb-2 font-sans leading-[30px]">{title}</h3>
        <p className="text-[#1A191E] text-[14px] mb-6 font-[500]" dangerouslySetInnerHTML={{ __html: description }} />
        <button className="bg-background-secondry text-[16px] text-text-primary px-4 py-2 rounded-lg font-medium flex items-center justify-center">Buy Tickets <FaArrowRight className="ml-2" /></button>
      </div>
    );
  };

const Events = () => {

        const [visibleEvents, setVisibleEvents] = useState(6); // Initially show 6 events

        const loadMoreEvents = () => {
            setVisibleEvents((prev) => Math.min(prev + 3, allEventsData.length)); // Load 3 more events on each click, max up to total events
        };

        const isMobile = window.innerWidth < 768; // You can detect mobile screen size
        const backgroundImage = isMobile 
            ? "/images/event-mobile-banner.png" 
            : "/images/event-banner.png";

    return (
        <>
        <title>Events</title>
            <Header />
            <PageBanner
                heading={<span className="mt-[4px] mb-[34px] md:mt-[0px] md:mb-[138px] block">Events</span>}
                paragraph=""
                backgroundImage={backgroundImage} // Dynamically set the background image
                className="bg-cover bg-center"
                style={{ maxWidth: '560px', marginTop: '10px' }}
            />
            {/* <section className="px-[0px] md:px-[50px] lg:px-[100px]">
            <div className="container mx-auto p-4 p-4 px-6 md:px-4 mb-10 max-w-[68rem] px-4 sm:px-6 lg:px-8">
            
            <p className="text-left mb-7 md:mb-[50px] mt-5 mt-0 md:mt-8 text-[16px] font-medium">
            Total Upcoming Events: <strong>{allEventsData.length}</strong>
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {allEventsData.slice(0, visibleEvents).map((event) => (
                <EventCard
                    key={event.id}
                    category={event.category}
                    postTime={event.postTime}
                    title={event.title}
                    description={event.description}
                />
                ))}
            </div>

            {visibleEvents < allEventsData.length && (
                <div className="text-center mt-8">
                <button onClick={loadMoreEvents} className="bg-background-primary text-white px-4 py-3 rounded-lg px-[200px]">
                    See More
                    
                </button>
                </div>
            )}
            </div>
            </section> */}
            <section className="w-full bg-cover bg-center py-4 px-[0px] md:px-[50px] lg:px-[100px]">
                <div className="container mx-auto p-4 p-4 px-6 md:px-4 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-4 flex  w-full">
                    <div className='max-w-[800px] m-auto'>
                        <h2 className='text-[44px] md:text-[55px] lg:text-[65px] font-sans font-[500] leading-tight mb-[50px] md:mb-[120px] mt-[50px] md:mt-[120px] text-center max-w-[1080px] m-auto'>Stay Tuned for Upcoming Events!</h2>
                    </div>
                    </div>
                </div>
            </section>
            <SubscribeSection />
            <Footer />
        </>
    )
}

export default Events;