// import React, { useEffect } from "react";
// import Header from '../components/Header.jsx'; 
// import Footer from '../components/Footer.jsx'; 

// const ContactUs = () => {
//     useEffect(() => {
//         document.title = "Contact Us";
//       }, []);

//     return (
//         <>
//             <Header />
//             <h1 className='mt-[80px]'>this is ContactUs</h1>
//             <Footer />
//         </>
//     )
// }

// export default ContactUs;

import React, { useState } from 'react';
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer.jsx'; 
import PageBanner from '../components/PageBanner.jsx';
import SubscribeSection from '../components/SubscribeSection.jsx';
import { NavLink } from 'react-router-dom';
import { RiMailUnreadLine } from "react-icons/ri";
import { GoCheckCircleFill } from "react-icons/go";
import { IoMdCall } from "react-icons/io";
import axios from 'axios'; // Import axios for making HTTP requests
import Loader from '../Loader';


const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState(''); // State to hold the email input
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null); // State to hold error messages
  const [apiIsLoading, setapiIsLoading] = useState(false);

  // const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => { 
    e.preventDefault(); // prevent page reload

    try {
        setapiIsLoading(true)
        const response = await axios.post('https://castme.productions/admin/api/contactus_data', {
          name: name, // Include name in the request
          email: email, // Include email in the request
          description: description 
        });

        if (response.data.status) {
            setapiIsLoading(false)
            setSubmitted(true); // Show success message
            setError(null); // Reset any previous error
        } else {
            setapiIsLoading(false)
            setError(response.data.message); // Set error message
        }
    } catch (err) {
        
        setapiIsLoading(false)
        console.error('Error subscribing:', err);
        setError('Subscription failed. Please try again.'); // Set error message for network issues
    }
};

    

    // const handleSubmit = (e) => {
    //     e.preventDefault(); // Prevent form submission from refreshing the page
    //     setSubmitted(true); // Set the form as submitted
    // };

    const isMobile = window.innerWidth < 768; // You can detect mobile screen size
    const backgroundImage = isMobile 
            ? "/images/contact-mobile-banner.png" 
            : "/images/contact-banner.png";
    return (
        <>
        <Loader isLoading={apiIsLoading}/>
        <title>Contact Us</title>
            <Header />
            <PageBanner
                heading={<span className="mt-[4px] mb-[34px] md:mt-[0px] md:mb-[138px] block">Contact Us</span>}
                backgroundImage={backgroundImage} // Dynamically set the background image
                className="bg-cover bg-center"
                style={{ maxWidth: '560px', marginTop: '10px' }}
            />
            <section className="w-full bg-cover bg-center mt-6 md:mt-[100px] mb-[0px] md:mb-[60px] px-[5px] md:px-[50px] lg:px-[100px]" style={{ paddingBottom : '3rem'}} >
                <div className="container mx-auto px-4 max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col md:flex-row gap-8 md:gap-0 items-start justify-between">
                        {/* Left Column (Text Content) */}
                        <div className="w-full md:w-1/2 flex flex-col  ">
                            <h2 className="tracking-[-2px] text-[#1A191E] text-[44px] md:text-[44px] lg:text-[50px] font-[500] mb-12 md:mb-10 font-sans leading-[48px] md:leading-[48px] lg:leading-[60px]">
                                Get In Touch
                            </h2>
                            {/* <p className="text-lg mb-0 md:mb-6 flex items-center">
                                <NavLink to='tel:(123)456-7890' className='text-lg mb-6 flex items-center'>
                                <div className="relative flex items-center justify-center bg-background-primary text-white p-2.5 rounded-full"> 
                                <IoMdCall className="text-[30px]" /> 
                                </div>
                                <div>
                                <p className='ml-4 font-semibold'>
                                (123)456-7890
                                </p>
                                </div>
                                </NavLink>
                            </p> */}
                            <p className="text-lg mb-0 md:mb-6 flex items-center">
                                <NavLink to='mailto:support@castme.productions' className='text-lg mb-6 flex items-center'>
                                <div className="relative flex items-center justify-center bg-background-primary text-white p-2.5 rounded-full"> {/* Background and Circle */}
                                <RiMailUnreadLine className="text-[26px]" /> {/* White Mail Icon */}
                                {/* Small White Dot */}
                                </div>
                                <div>
                                <p className='ml-4 font-semibold'>
                                support@castme.productions
                                </p>
                                <p className="text-sm text-gray-400 block ml-4">Jeremy Doman // Co-Founder</p>
                                </div>
                                </NavLink>
                            </p>
                            
                        </div>



                        {/* Right Column (Form) */}
                        <div className="w-full md:w-1/2 mt-3 mb-8">
                        
                        {!submitted ? (
                        <div>
                          <h2 className="text-[18px] font-sans font-[700] mb-4">
                            Please complete and submit the form below.
                          </h2>
                          <p className="text-[16px] mb-4 text-gray-400 text-semibold">
                            Connect with us for inquiries about casting, productions, or networking opportunities!
                          </p>
                          <form className="space-y-4" onSubmit={handleSubmit}>
                            <input
                              type="text"
                              placeholder="Name*"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="w-full p-4 border border-[#F8F8F8] rounded-lg xl:w-[498.75px] xl:h-[58px]"
                              style={{ background:'#F8F8F8' }}
                              required
                            />
                            <input
                              type="email"
                              placeholder="Email Address*"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="w-full p-4 border border-[#F8F8F8] rounded-lg  xl:w-[498.75px] xl:h-[58px]"
                              style={{ background:'#F8F8F8' }}
                              required
                            />
                            <textarea
                              placeholder="Message*"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="w-full h-[170px] p-4 border border-[#F8F8F8] rounded-lg  xl:w-[498.75px] xl:h-[58px]"
                              style={{ background:'#F8F8F8' }}
                              required
                            ></textarea>
                            <button
                              type="submit"
                              className="w-full  xl:w-[498.75px] xl:h-[58px] bg-yellow-400 text-black py-3 rounded-lg font-[500] text-[16px] transition duration-300 border border-[#FFC727] hover:text-[#FFC727] hover:bg-transparent hover:border hover:border-[#FFC727]"
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      ) : (
                        <div className="text-text-primary text-[20px] md:text-[23px] font-semibold ">
                            <div className='flex items-center'>
                            <GoCheckCircleFill className='text-[30px] text-text-secondry' />
                            <span className='ml-3'>Thanks for your submission!</span>
                            </div>
                            <p className='text-[16px] text-gray-400 font-[500] mt-3'>We will reach out to you in 24 hours.</p>
                        </div>
                        // <p>We will reach out to you in 24hours.</p>
                      )}
                        </div>
                    </div>
                </div>
            </section>

            <SubscribeSection />
            <Footer />
        </>
    );
}

export default ContactUs;
