import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTiktok, FaInstagram } from "react-icons/fa";

const Footer = () => {
    const disableContextMenu = (e) => {
        e.preventDefault();
      };
    return (
        <>
            <footer className="w-full bg-white">
                <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center border-b-[1px] border-[#E8E8E8] py-8 max-w-7xl px-4 sm:px-6 lg:px-8">
                    
                    {/* Left Column: Logo and Social Icons */}
                    <div className="mb-6 md:mb-0">
                    <div className='mb-8'>
                        <NavLink to="/">
                            <img src="/images/logo.svg" alt="logo" className='w-28 md:w-[124px] mr-0 max-md:mr-0 max-lg:mr-5' onContextMenu={disableContextMenu} />
                        </NavLink>
                    </div>
                        <div className="flex justify-center md:justify-start lg:justify-start space-x-4">
                            <NavLink 
                                to="https://www.instagram.com/castmeapp?igsh=MXUwcHhhbnRvMGp2Nw=="
                                className="text-gray-500 hover:text-pink-600 transition-all duration-300 ease-in-out" target='_blank'
                            >
                                <FaInstagram className='text-[20px] text-text-primary' />
                            </NavLink>
                            <NavLink 
                                to="https://tiktok.com"
                                className="text-gray-500 hover:text-blue-500 transition-all duration-300 ease-in-out" target='_blank'
                            >
                                <FaTiktok className='text-[20px] text-text-primary' />
                            </NavLink>
                        </div>
                    </div>

                    {/* Right Column: Horizontal Menu */}
                    <div>
                        <nav>
                            <ul className="flex flex-col md:flex-row md:block lg:flex gap-8 space-x-0 md:space-x-0 lg:space-x-20 text-center md:text-right lg:text-right">
                                <li>
                                    <NavLink to='/about-us' className='text-[#1A191E] hover:text-text-secondry font-semibold text-[13px]'>About</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/partnerships' className='text-[#1A191E] hover:text-text-secondry font-semibold text-[13px]'>
                                        Partnerships
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/events' className='text-[#1A191E] hover:text-text-secondry font-semibold text-[13px]'>
                                        Events
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/contact-us' className='text-[#1A191E] hover:text-text-secondry font-semibold text-[13px]'>
                                        Contact Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/privacy-terms' className='text-[#1A191E] hover:text-text-secondry font-semibold text-[13px]'>
                                        Privacy Policy and Terms of Service
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </footer>

            {/* Footer Bottom */}
            <section id="footer-bottom" className="w-full bg-white py-4">
                <p className="text-center text-gray-500 text-[12px] ">© 2024. All rights reserved.</p>
            </section>
        </>
    );
};

export default Footer;
