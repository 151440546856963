import React from "react";
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer.jsx'; 
import PageBanner from "../components/PageBanner.jsx";
import SubscribeSection from '../components/SubscribeSection.jsx';

const EventsDetail = () => {

    const isMobile = window.innerWidth < 768; // You can detect mobile screen size
    const backgroundImage = isMobile 
            ? "/images/event-mobile-banner.png" 
            : "/images/event-banner.png";

    return (
        <>
        <title>Events Detail</title>
            <Header />
            <PageBanner
                heading={<span className="mt-[4px] mb-[34px] md:mt-[0px] md:mb-[138px] block">Events</span>}
                paragraph=""
                backgroundImage={backgroundImage} // Dynamically set the background image
                className="bg-cover bg-center"
                style={{ maxWidth: '560px', marginTop: '10px' }}
            />

            <section className="w-full bg-cover bg-center py-4 px-[0px] md:px-[50px] lg:px-[100px]">
                <div className="container mx-auto p-4 h-full flex items-center">
                    <div className="grid grid-cols-1 gap-4 flex  w-full">
                    <div className=''>
                        <h2 className='text-[38px] md:text-[55px] lg:text-[96px] font-sans font-semibold leading-tight mb-[50px] md:mb-[150px] mt-[50px] md:mt-[150px] text-center'>Stay Tuned for Upcoming Events!</h2>
                    </div>
                    </div>
                </div>
            </section>


            <SubscribeSection />
            <Footer />
        </>
    )
}

export default EventsDetail;