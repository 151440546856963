import React from 'react';
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer';
import HomeBanner from '../components/HomeBanner.jsx';
import { NavLink } from 'react-router-dom';
import IconBox from '../components/IconBox.jsx';
import MobileSlider from '../components/MobileSlider.jsx';
import MobileSlider1 from '../components/MobileSlider1.jsx';
import SubscribeSection from '../components/SubscribeSection.jsx';
import { useScroll } from '../components/ScrollContext';

const Home = () => {

    const disableContextMenu = (e) => {
        e.preventDefault();
      };

      const { section1Ref, section2Ref } = useScroll();

  return (
    <>
    <title>Home</title>
      <Header />
      <HomeBanner />
      <section ref={section1Ref} className="w-full bg-cover bg-center py-8 md:pt-[90px] px-[0px] md:px-[50px] lg:px-[100px]">
                <div className="container mx-auto p-6 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex  w-full">
                    <div className=''>
                        <h2 className='tracking-[-2px] text-[44px] md:text-[40px] lg:text-[50px] font-sans font-semibold leading-[50px] md:leading-[1.10] mb-[42px] md:mb-[32px] max-w-[270px] md:max-w-[310px]'>For Cast. Crew. & Creatives.</h2>
                        <div className='flex flex-col gap-5'>
                        <IconBox title="Trusted Auditions" para="Access funded, trustworthy auditions in film and theater. Say goodbye to spam and scams—our casting directors and producers are vetted and verified." />
                        <IconBox title="Exclusive Events" para="Enjoy free invitations to exclusive performances, shows, and social events. Only members get access to previews, readings, or behind-the-scenes experiences that elevate their career" />
                        <IconBox title="Transparency in Talent Management" para="Our sleek user interface keeps you informed. Track your audition status, receive live updates, and communicate directly with casting directors." />
                        </div>
                        <div className='hidden md:flex gap-5 justify-center mt-8'>
                        <NavLink to="" target='_black'>
                            <img src="/images/appstore.svg" alt="App Store" className='w-[145.5px] h-[48.75px] max-lg:mr-5 mb-4 md:w-[194px] md:h-[65px]' onContextMenu={disableContextMenu} />
                        </NavLink> 
                        <NavLink to="" target='_black' className="mr-2">
                            <img src="/images/googleplay.svg" alt="Play store" className='w-[165px] h-[48.75px]  max-lg:mr-5 md:w-[220px] md:h-[65px]' onContextMenu={disableContextMenu} />
                        </NavLink>
                        </div>
                        <div>
                        
                          <NavLink to=""> 
                              <button className='text-[16px] md:text-[22px] uppercase font-[Montserrat] font-bold px-[30px] py-[8px] rounded text-white mt-5 bg-custom-gradient' >JOIN NOW</button>
                          </NavLink>
                        </div>
                    </div>
                    <div className="auto text-center">
                        <h2 className='bg-background-primary text-white text-[14px] leading-tight text-center block m-auto p-2 rounded-lg mb-4 mt-4 inline-block'>How it works</h2>
                        <MobileSlider />
                    </div>
                    </div>
                </div>
            </section>
            <section ref={section2Ref} className="w-full bg-cover bg-center py-4 mt-[-20px] md:mt-4 px-[0px] md:px-[50px] lg:px-[100px] md:pt-[90px]" >
                <div className="container mx-auto p-6 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex  w-full">
                    <div className="auto text-center order-2 md:order-1">
                        <h2 className='bg-background-primary text-white text-[14px] leading-tight text-center block m-auto p-2 rounded-lg mb-4 mt-4 inline-block'>How it works</h2>
                        <MobileSlider1 />
                    </div>
                    <div className='order-1 md:order-2'>
                        <h2 className='tracking-[-2px] text-[44px] md:text-[40px] lg:text-[50px] font-sans font-semibold leading-[50px] md:leading-[1.10] mb-[42px] md:mb-[32px] max-w-[365px] md:max-w-[650px]'>For Casting Directors. Producers. & Writers.</h2>
                        <div className='flex flex-col gap-6'>
                        <IconBox title="Advanced Casting Technology" para="Utilize cutting-edge tools to search, filter, and rank talent. Send messages and post production updates to cast and crew. Streamline the audition process with ease and precision." />
                        <IconBox title="Funding and Investor Matching" para="Need funding? We offer production funding and match writers with producers or investors. Your intellectual property is protected and your ideas are safe with us." />
                        <IconBox title="Extensive Network" para="Connect with a vast network of talented cast and crew for your film or theatre projects. Find the perfect fit for your production needs." />
                        <IconBox title="Exclusive Industry Events" para="Gain access to exclusive industry events designed to network and collaborate with other professionals in the field." />
                        </div>
                        <div className='hidden md:flex gap-5 justify-center mt-8'>
                        <NavLink to="" target='_black'>
                            <img src="/images/appstore.svg" alt="App Store" className='w-[145.5px] h-[48.75px] max-lg:mr-5 mb-4 md:w-[194px] md:h-[65px]' onContextMenu={disableContextMenu} />
                        </NavLink> 
                        <NavLink to="" target='_black' className="mr-2">
                            <img src="/images/googleplay.svg" alt="Play store" className='w-[165px] h-[48.75px]  max-lg:mr-5 md:w-[220px] md:h-[65px]' onContextMenu={disableContextMenu} />
                        </NavLink>
                        </div>
                        <div>
                        
                          <NavLink to=""> 
                              <button className='text-[16px] md:text-[22px] uppercase font-[Montserrat] font-bold px-[30px] py-[8px] rounded text-white mt-5 bg-custom-gradient' >JOIN NOW</button>
                          </NavLink>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <div className="mt-[0px] md:mt-[180px]">
                <SubscribeSection />
            </div>
      <Footer />
    </>
  );
};

export default Home;
