import React from "react";
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer.jsx'; 
import PageBanner from "../components/PageBanner.jsx";
import SubscribeSection from '../components/SubscribeSection.jsx';
import { Link } from 'react-router-dom';

const PrivacyPolicyTermsService = () => {
  return (
    <>
    <title>Privacy Policy and Terms of Service</title>
    <Header />
    <PageBanner
        heading={<span className="mt-[4px] mb-[34px] md:mt-[0px] md:mb-[56px] block text-[35px] md:text-[75px]">Privacy Policy and Terms of Service</span>}
        paragraph=""
        backgroundImage="/images/about-banner.png" // Pass the image URL as a prop
    />
    <section className="px-[0px] md:px-[50px] lg:px-[100px]">
      <div className="container mx-auto p-4 max-w-[68rem] px-4 sm:px-6 lg:px-8">
      <h1 className="text-[30px] md:text-[40px] lg:text-[50px] font-[500] mb-4 font-sans leading-[35px] md:leading-[45px] lg:leading-[60px] mt-12">
      Terms and Conditions
    </h1>
    <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">
      Agreement between User and Cast Me Mobile Applications
    </h2>
    <p>
    Welcome to castme.productions. The Cast Me mobile application (the "Site") is comprised of
various web pages operated by Cast Me Inc. ("Cast Me"). castme.productions is offered to you
conditioned on your acceptance without modification of the terms, conditions, and notices
contained herein (the "Terms"). Your use of castme.productions constitutes your agreement to
all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
castme.productions is a Business Description Site.

    </p>
    </section>
  

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Privacy</h2>
    <p>
    Your use of castme.productions is subject to Cast Me's Privacy Policy. Please review our
    Privacy Policy, which also governs the Site and informs users of our data collection practices.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Electronic Communications</h2>
    <p>
    Visiting castme.productions or sending emails to Cast Me constitutes electronic
communications. You consent to receive electronic communications and you agree that all
agreements, notices, disclosures and other communications that we provide to you
electronically, via email and on the Site, satisfy any legal requirement that such
communications be in writing.

    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Your Account</h2>
    <p>
    If you use this site, you are responsible for maintaining the confidentiality of your account and
password and for restricting access to your computer, and you agree to accept responsibility for
all activities that occur under your account or password. You may not assign or otherwise
transfer your account to any other person or entity. You acknowledge that Cast Me is not
responsible for third party access to your account that results from theft or misappropriation of
your account. Cast Me and its associates reserve the right to refuse or cancel service,
terminate accounts, or remove or edit content in our sole discretion.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Children Under Thirteen</h2>
    <p className="mb-3">
    Cast Me collects personally identifiable information from children under the age of thirteen. Cast
Me collects this information for the following reason(s):

    </p>
    <p className="mb-3">
    Personally identifiable information such as name, contact information, and video and picture
content is used to create profiles for subscribes to network with other users on the platform.
When users apply for productions, the owner has the ability to review an applicants public data
to determine if they will cast that individual. Users who are in a project together may see one
another’s data as well.
    </p>
    <p>
    If you are under the age of thirteen, you must ask your parent or guardian for permission to use
this website. If you are a parent and you have questions regarding our data collection practices,
please contact us using the information provided at the end of this Agreement. If you are under
18, you may use castme.productions only with permission of a parent or guardian.

    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Cancellation/Refund Policy</h2>
    <p>
    You may cancel your subscription at any time. Any cancellations made after the purchase of a
Cast Me subscription will not qualify for a refund. Please contact us at
support@castme.productions with any questions
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Links to Third-Party Sites/Services</h2>
    <p>
    castme.productions may contain links to other websites ("Linked Sites"). The Linked Sites are
not under the control of Cast Me and Cast Me is not responsible for the contents of any Linked
Site, including without limitation any link contained in a Linked Site, or any changes or updates
to a Linked Site. Cast Me is providing these links to you only as a convenience, and the
inclusion of any link does not imply endorsement by Cast Me of the site or any association with
its operators.<br />
Certain services made available via castme.productions are delivered by third party sites and
organizations. By using any product, service or functionality originating from the
castme.productions domain, you hereby acknowledge and consent that Cast Me may share
such information and data with any third party with whom Cast Me has a contractual
relationship to provide the requested product, service or functionality on behalf of
castme.productions users and customers.

    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">No Unlawful or Prohibited Use/Intellectual Property</h2>
    <p className="mb-3">
    You are granted a non-exclusive, non-transferable, revocable license to access and use
castme.productions strictly in accordance with these terms of use. As a condition of your use of
the Site, you warrant to Cast Me that you will not use the Site for any purpose that is unlawful or
prohibited by these Terms. You may not use the Site in any manner which could damage,
disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of
the Site. You may not obtain or attempt to obtain any materials or information through any
means not intentionally made available or provided for through the Site.</p>
<p className="mb-3">All content included as part of the Service, such as text, graphics, logos, images, as well as the
compilation thereof, and any software used on the Site, is the property of Cast Me or its
suppliers and protected by copyright and other laws that protect intellectual property and
proprietary rights. You agree to observe and abide by all copyright and other proprietary
notices, legends or other restrictions contained in any such content and will not make any
changes thereto.</p>
<p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
Cast Me content is not for resale. Your use of the Site does not entitle you to make any
unauthorized use of any protected content, and in particular you will not delete or alter any
proprietary rights or attribution notices in any content. You will use protected content solely for
your personal use, and will make no other use of the content without the express written
permission of Cast Me and the copyright owner. You agree that you do not acquire any
ownership rights in any protected content. We do not grant you any licenses, express or
implied, to the intellectual property of Cast Me or our licensors except as expressly authorized
by these Terms.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Use of Communication Services</h2>
    <p className="mb-3">
    The Site may contain bulletin board services, chat areas, news groups, forums, communities,
personal web pages, calendars, and/or other message or communication facilities designed to
enable you to communicate with the public at large or with a group (collectively,
"Communication Services"). You agree to use the Communication Services only to post, send
and receive messages and material that are proper and related to the particular
Communication Service.
    </p>
    <p className="mb-3">
    By way of example, and not as a limitation, you agree that when using a Communication
Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights
(such as rights of privacy and publicity) of others; publish, post, upload, distribute or
disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful
topic, name, material or information; upload files that contain software or other material
protected by intellectual property laws (or by rights of privacy of publicity) unless you own or
control the rights thereto or have received all necessary consents; upload files that contain
viruses, corrupted files, or any other similar software or programs that may damage the
operation of another's computer; advertise or offer to sell or buy any goods or services for any
business purpose, unless such Communication Service specifically allows such messages;
conduct or forward surveys, contests, pyramid schemes or chain letters; download any file
posted by another user of a Communication Service that you know, or reasonably should know,
cannot be legally distributed in such manner; falsify or delete any author attributions, legal or
other proper notices or proprietary designations or labels of the origin or source of software or
other material contained in a file that is uploaded; restrict or inhibit any other user from using
and enjoying the Communication Services; violate any code of conduct or other guidelines
which may be applicable for any particular Communication Service; harvest or otherwise collect
information about others, including e-mail addresses, without their consent; violate any
applicable laws or regulations.
    </p>
    <p className="mb-3">
    Cast Me has no obligation to monitor the Communication Services. However, Cast Me reserves
the right to review materials posted to a Communication Service and to remove any materials in
its sole discretion. Cast Me reserves the right to terminate your access to any or all of the
Communication Services at any time without notice for any reason whatsoever.
    </p>
    <p className="mb-3">
    Cast Me reserves the right at all times to disclose any information as necessary to satisfy any
applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to
remove any information or materials, in whole or in part, in Cast Me's sole discretion.
Always use caution when giving out any personally identifying information about yourself or your
children in any Communication Service. Cast Me does not control or endorse the content,
messages or information found in any Communication Service and, therefore, Cast Me
specifically disclaims any liability with regard to the Communication Services and any actions
resulting from your participation in any Communication Service. Managers and hosts are not
authorized Cast Me spokespersons, and their views do not necessarily reflect those of Cast
Me. Materials uploaded to a Communication Service may be subject to posted limitations on
usage reproduction and/or dissemination. You are responsible for adhering to such limitations if
you upload the materials.
    </p>
    <p className="mb-3">
    Materials Provided to castme.productions or Posted on Any Cast Me Web Page Cast Me does
not claim ownership of the materials you provide to castme.productions (including feedback
and suggestions) or post, upload, input or submit to any Cast Me Site or our associated
services (collectively "Submissions"). However, by posting, uploading, inputting, providing or
submitting your Submission you are granting Cast Me, our affiliated companies and necessary
sublicensees permission to use your Submission in connection with the operation of their
Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly
display, publicly perform, reproduce, edit, translate and reformat your Submission; and to
publish your name in connection with your Submission.
    </p>
    <p className="mb-3">
    No compensation will be paid with respect to the use of your Submission, as provided herein.
Cast Me is under no obligation to post or use any Submission you may provide and may remove
any Submission at any time in Cast Me's sole discretion.
    </p>
    <p>
    By posting, uploading, inputting, providing or submitting your Submission you warrant and
represent that you own or otherwise control all of the rights to your Submission as described in
this section including, without limitation, all the rights necessary for you to provide, post, upload,
input or submit the Submissions.
    </p>
  </section>


  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">International Users</h2>
    <p>
    The Service is controlled, operated and administered by Cast Me from our offices within the
USA. If you access the Service from a location outside the USA, you are responsible for
compliance with all local laws. You agree that you will not use the Cast Me Content accessed
through castme.productions in any country or in any manner prohibited by any applicable laws,
restrictions or regulations.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Indemnification</h2>
    <p>
    You agree to indemnify, defend and hold harmless Cast Me, its officers, directors, employees,
agents and third parties, for any losses, costs, liabilities and expenses (including reasonable
attorney's fees) relating to or arising out of your use of or inability to use the Site or services,
any user postings made by you, your violation of any terms of this Agreement or your violation
of any rights of a third party, or your violation of any applicable laws, rules or regulations. Cast
Me reserves the right, at its own cost, to assume the exclusive defense and control of any
matter otherwise subject to indemnification by you, in which event you will fully cooperate with
Cast Me in asserting any available defenses.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Arbitration</h2>
    <p>
    In the event the parties are not able to resolve any dispute between them arising out of or
concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or
otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved
only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a
single neutral arbitrator and administered by the American Arbitration Association, or a similar
arbitration service selected by the parties, in a location mutually agreed upon by the parties. The
arbitrator's award shall be final, and judgment may be entered upon it in any court having
jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out
of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable
attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms
and Conditions or any disputes arising as a result of these Terms and Conditions, whether
directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The
parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this
provision. The entire dispute, including the scope and enforceability of this arbitration provision
shall be determined by the Arbitrator. This arbitration provision shall survive the termination of
these Terms and Conditions.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Class Action Waiver</h2>
    <p>
    Any arbitration under these Terms and Conditions will take place on an individual basis; class
arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE
THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL
CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS,
COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A
PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you
and Cast Me agree otherwise, the arbitrator may not consolidate more than one person's
claims, and may not otherwise preside over any form of a representative or class proceeding.

    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Liability Disclaimer</h2>
    <p className="mb-3">
    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
INFORMATION HEREIN. CAST ME INC. AND/OR ITS SUPPLIERS MAY MAKE
IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
    </p>
    <p className="mb-3">
    CAST ME INC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES
AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
CONDITION OF ANY KIND. CAST ME INC. AND/OR ITS SUPPLIERS HEREBY DISCLAIM
ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL
IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
    </p>
    <p className="mb-3">
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
CAST ME INC. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR
RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR
FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE
OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
OR OTHERWISE, EVEN IF CAST ME INC. OR ANY OF ITS SUPPLIERS HAS BEEN
ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS
DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS
OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
DISCONTINUE USING THE SITE.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Termination/Access Restriction</h2>
    <p className="mb-3">
    Cast Me reserves the right, in its sole discretion, to terminate your access to the Site and the
related services or any portion thereof at any time, without notice. To the maximum extent
permitted by law, this agreement is governed by the laws of the State of Delaware and you
hereby consent to the exclusive jurisdiction and venue of courts in Delaware in all disputes
arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction
that does not give effect to all provisions of these Terms, including, without limitation, this
section.
    </p>
    <p className="mb-3">
    You agree that no joint venture, partnership, employment, or agency relationship exists between
you and Cast Me as a result of this agreement or use of the Site. Cast Me's performance of this
agreement is subject to existing laws and legal process, and nothing contained in this
agreement is in derogation of Cast Me's right to comply with governmental, court and law
enforcement requests or requirements relating to your use of the Site or information provided to
or gathered by Cast Me with respect to such use. If any part of this agreement is determined to
be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty
disclaimers and liability limitations set forth above, then the invalid or unenforceable provision
will be deemed superseded by a valid, enforceable provision that most closely matches the
intent of the original provision and the remainder of the agreement shall continue in effect.
    </p>
    <p>
    Unless otherwise specified herein, this agreement constitutes the entire agreement between the
user and Cast Me with respect to the Site and it supersedes all prior or contemporaneous
communications and proposals, whether electronic, oral or written, between the user and Cast
Me with respect to the Site. A printed version of this agreement and of any notice given in
electronic form shall be admissible in judicial or administrative proceedings based upon or
relating to this agreement to the same extent and subject to the same conditions as other
business documents and records originally generated and maintained in printed form. It is the
express wish to the parties that this agreement and all related documents be written in English.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Changes to Terms</h2>
    <p>
    Cast Me reserves the right, in its sole discretion, to change the Terms under which
castme.productions is offered. The most current version of the Terms will supersede all previous
versions. Cast Me encourages you to periodically review the Terms to stay informed of our
updates.
    </p>
  </section>

  <section className="mb-6">
    <h2 className="text-[18px] font-semibold mb-2 font-sans">Contact Us</h2>
    <p>
      Cast Me Inc.<br />
      40 E Skokie Blvd 105<br />
      Northbrook, Illinois 60062<br />
      Email Address: jeremy@castme.productions
    </p>
    <p>Effective as of August 13, 2023</p>
  </section>


      </div>
    </section>
    <SubscribeSection />
    <Footer />
    </>
  );
};

export default PrivacyPolicyTermsService;
