import React, { useEffect } from "react";
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer.jsx'; 
import PageBanner from '../components/PageBanner.jsx';
import SubscribeSection from '../components/SubscribeSection.jsx';
import { RiMailUnreadLine } from "react-icons/ri";

const Partnerships = () => {

    const isMobile = window.innerWidth < 768; // You can detect mobile screen size
      const backgroundImage = isMobile 
            ? "/images/partnership-mobile-banner.png" 
            : "/images/partnerships-banner.png";

    return (
        <>
        <title>Partnerships</title>
            <Header />
            <PageBanner
                heading="Partnerships"
                paragraph={
                    <span className="max-w-[335px] md:max-w-[450px] block mb-[0px] md:mb-[50px]">
                        For Film and Theater Investors, Grantors, and Producers
                    </span>
                }
                backgroundImage={backgroundImage} // Dynamically set the background image
                className="bg-cover bg-center"
                style={{ maxWidth: '560px' }}
            />
            <section className="w-full bg-cover bg-center pt-4 pb-4 md:pt-[100px] md:pb-[102px] mt-4 px-[0px] md:px-[50px] lg:px-[100px]">
                <div className="container mx-auto p-4 px-6 md:px-4 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-28 flex  w-full">
                    <div className=''>
                        <div class="">
                            <h2 class="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-12 md:mb-4 font-sans leading-[48px] md:leading-[45px] lg:leading-[60px] tracking-[-2px]">Curated Production Proposals</h2>
                            <p class=" mb-14 md:mb-5 text-[16px] md:text-[17.96px] text-[#1A191E] font-inter font-[500]">Our dedicated team of seasoned experts meticulously vets thousands of script proposals to ensure they align with the values, motivations, and requirements that institutions look for in their investments. By partnering with Cast Me, grant organizations can gain access to high-quality, legitimate productions.</p>
                        </div>
                          
                    </div>
                        <div>
                        
                        <div class="">
                            <h2 class="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-12 md:mb-4 font-sans leading-[48px] md:leading-[45px] lg:leading-[60px] tracking-[-2px] mt-[45px] md:mt-[0px]">Prominent Brand Exposure</h2>
                            <p class=" mb-14 md:mb-5 text-[16px] md:text-[17.96px] text-[#1A191E] font-inter font-[500]">Cast Me offers institutions an opportunity to showcase their support for the arts and retain brand ownership on Cast Me’s digital platform. We offer visibility by displaying the partner institution alongside the production it supports, ensuring they receive film credits and recognition for their contribution.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full bg-cover bg-center py-4 px-[0px] md:px-[50px] lg:px-[100px]">
                <div className="container mx-auto p-4 px-6 md:px-4 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-28 flex  w-full">
                    <div className=''>
                        <div class="">
                            <h2 class="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-12 md:mb-4 font-sans leading-[48px] md:leading-[45px] lg:leading-[60px] tracking-[-2px]">Leverage Funding with Control</h2>
                            <p class=" mb-14 md:mb-5 text-[16px] md:text-[17.96px] text-[#1A191E] font-inter font-[500]">Film and theater grantors and investors can maximize their funding impact by partnering with other organizations in Cast Me’s network or by potentially receiving additional contributions from Cast Me’s funding pool. Furthermore, organizations can retain control over fund disbursement to comply with their own requirements.</p>
                        </div>
                          
                    </div>
                        <div>
                        
                        <div class="">
                            <h2 class="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-12 md:mb-4 font-sans leading-[48px] md:leading-[45px] lg:leading-[60px] tracking-[-2px] mt-[45px] md:mt-[0px]">Why Choose Cast Me for Partnerships?</h2>
                            <p class=" mb-5 text-[16px] md:text-[17.96px] text-[#1A191E] font-inter font-[500]">Unparalleled expertise in the entertainment industry ensures grants dollars are spent efficiently and in line with industry standards. Flexible partnership options allow partners to explore opportunities for financial contributions and collaboration while maintaining various levels of control in the sourcing and production process</p>
                            <p class=" mb-2 md:mb-5 text-[16px] md:text-[17.96px] text-[#1A191E] font-inter font-[500]">Prominent recognition for each institution’s commitment to the arts, with visible branding and credits on Cast Me’s digital platform.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className="max-w-[1100px] flex  bg-background-secondry text-white mx-auto p-8 px-6 md:px-8 py-[55px] pb-[58px] md:py-[30px] md:pb-[18px] rounded-lg mt-16 md:mt-[8rem] mb-0 md:mb-[190px] mx-[0px] md:mx-[50px] lg:mx-[100px] lg:mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                
                <div className="flex items-center ">
                <h2 class="pl-[0px] md:pl-[20px] text-[44px] md:text-[44px] lg:text-[64px] font-[500] mb-10 md:mb-4 font-sans leading-[48px] md:leading-[48px] lg:leading-[60px] tracking-[-2px] max-w-[500px]">Want to invest or collaborate in productions? </h2>
                </div>

                <div className=" text-[#1A191E] p-0">
                <div className="flex items-start1">
                    
                    <div className="p-0">
                 
                    <p className="pl-0 text-[16px] md:text-[17.96px] font-[500] max-w-[500px] ">
                    If you're interested in investing in productions or collaborating with the Cast Me, please email jeremy@castme.productions.
                    </p>

               
                    <div className="mt-10 md:mt-4 flex items-center">
                        
                        <div className="relative flex items-center justify-center bg-background-primary text-white p-2.5 rounded-full">
                        <RiMailUnreadLine className="text-[24px]" />
                       
                        </div>

                        <div className="ml-3">
                        <p className="font-semibold text-[18px] text-[#1A191E]">Jeremy Doman</p>
                        <p className="text-sm text-gray-400">CEO</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div> */}


            <div className="md:max-w-[1100px] lg:max-w-[1180px] mx-auto p-6 md:px-8 py-[55px] md:py-[30px] bg-background-secondry text-white rounded-lg mt-16 md:mt-[8rem] mb-0 md:mb-[190px]">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
    {/* Left Grid Item */}
    <div className="flex items-center">
      <h2 className="text-[#293762] pl-0 md:pl-[20px] text-[44px] md:text-[44px] lg:text-[64px] font-medium leading-[48px] lg:leading-[60px] tracking-[-2px] max1-w-[500px]">
        Want to invest or collaborate in productions?
      </h2>
    </div>

    {/* Right Grid Item */}
    <div className="text-[#1A191E]">
      <p className="text-[16px] md:text-[18px] font-medium max1-w-[500px] mb-4">
        {/* If you're interested in investing in productions or collaborating with Cast Me, please email jeremy@castme.productions. */}
        If you’re interested in investing in productions or collaborating with Cast Me, please email jeremy@castme.productions.
      </p>
      <div className="flex items-center mt-4">
        <div className="relative flex items-center justify-center bg-background-primary text-white p-2.5 rounded-full">
          <RiMailUnreadLine className="text-[24px]" />
        </div>
        <div className="ml-3">
          <p className="font-semibold text-[18px] text-[#1A191E]">Jeremy Doman</p>
          <p className="text-sm text-[#1A191E80]">CEO</p>
        </div>
      </div>
    </div>
  </div>
</div>

            <SubscribeSection />
            <Footer />
        </>
    )
}

export default Partnerships;