import React from 'react';
import { NavLink } from 'react-router-dom';


const PageBanner = (props) => {

    return (
        <>
          <section className="w-full bg-cover bg-center md:h-custom-height pt-[84px] pb-[75px] md:pt-[56px] md:pb-[15px] mt-[68px] px-[20px] md:px-[50px] lg:px-[100px]" 
           style={{ backgroundImage: `url(${props.backgroundImage})` }} >
                <div className="container mx-auto p-4 h-full flex items-center max-w-[68rem] px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 flex items-center pt-[0px] pb-[0px] md:pt-[0px] md:pb-[30px] w-full">
                    <div className='order-2 md:order-1 '>
                        <h1 className='tracking-[-2px] text-text-secondry text-[55px] font-sans font-medium leading-[42px] md:leading-[65px] lg:leading-[82px] mb-5 md:text-[60px] lg:text-[75px]'>{props.heading}</h1>
                        <p className='text-white text-[16px] font-[Inter] leading-[27px] md:leading-[34px] mb-4 md:text-[18px] md:text-[20px]'>{props.paragraph}</p>
                    </div>
                    
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageBanner;