import React, { useState } from 'react';
import { GoCheckCircleFill } from "react-icons/go";
import axios from 'axios'; // Import axios for making HTTP requests
import Loader from '../Loader';

const SubscribeSection = () => {
    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState(''); // State to hold the email input
    const [error, setError] = useState(null); // State to hold error messages
    const [apiIsLoading, setapiIsLoading] = useState(false);

    const handleSubmit = async (e) => { 
        e.preventDefault(); // prevent page reload

        try {
            setapiIsLoading(true)
            const response = await axios.post('https://castme.productions/admin/api/mailchimp_subscribe', {
                email: email // Send the email to the API
            });

            if (response.data.status) {
                setapiIsLoading(false)
                setSubmitted(true); // Show success message
                setError(null); // Reset any previous error
            } else {
                setapiIsLoading(false)
                setError(response.data.message); // Set error message
            }
        } catch (err) {
            
            setapiIsLoading(false)
            console.error('Error subscribing:', err);
            setError('Subscription failed. Please try again.'); // Set error message for network issues
        }
    };

    return (
        <>
        <Loader isLoading={apiIsLoading}/>
        <section className="bg-background-primary py-[54px] pb-[80px] px-[0px] md:px-[50px] lg:px-[100px] w-full">
            <div className=" mx-auto px1-[30px] md:px1-4 max-w-[68rem] px-4 md:px-0 sm:px1-6 lg:px1-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  
                    <div className='max-w-[438px]'>
                        <h4 className='text-text-secondry font-[500] text-[18px] font-sans'>Join the Waitlist</h4>
                        <h2 className="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-4 text-white font-sans">Stay notified</h2>
                        <p className="text-white mb-5">
                            Our early access members will get a free or discounted subscription and be eligible for exclusive events and ticket access to Cast Me sponsored events and shows.
                        </p>
                        <p className="text-white">
                            Whether it’s getting your script funded or auditioning for high value and trustworthy opportunities in film and theater, we’ve got you covered.
                        </p>
                    </div>

                   
                    <div className='items-center w-full m-auto'>
                        <div>
                            {!submitted ? (
                                <form className="p-0 md:p-6" onSubmit={handleSubmit}>
                                    <label className="block text-[18px] font-semibold text-white mb-4 font-sans" htmlFor="email">
                                        Join the Cast Me Community  
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="w-full p-3 border border-gray-300 rounded-lg mb-4  xl:w-[498.75px] xl:h-[58px]"
                                        placeholder="Enter your email address"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} // Update email state
                                    />
                                    <button
                                        type="submit"
                                        className="w-full  xl:w-[498.75px] xl:h-[58px] bg-background-secondry text-text-primary font-[500] py-3 rounded-lg text-[16px] border border-[#FFC727] hover:text-[#FFC727] hover:bg-background-primary hover:border hover:border-[#FFC727] text-[#FFC727] transition duration-300"
                                    >
                                        Join
                                    </button>
                                    {error && <p className="text-red-500 mt-2">{error}</p>} {/* Show error message */}
                                </form>
                            ) : (
                                <div className="text-white text-[20px] md:text-[23px] font-semibold flex items-center">
                                    <GoCheckCircleFill className='text-[30px] text-text-secondry' />
                                    <span className='ml-3'>Thanks for joining our community!</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        
        </>
    );
};

export default SubscribeSection;
