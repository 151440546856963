import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Partnerships from './pages/Partnerships';
import Events from './pages/Events';
import ContactUs from './pages/ContactUs';
import EventsDetail from './pages/EventsDetail';
import ComingSoon from './pages/ComingSoon';
import ScrollToTop from './components/ScrollToTop';
import PrivacyPolicyTermsService from './pages/PrivacyPolicyTermsService';

function App() {
  return (
    <>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/partnerships" element={<Partnerships />} />
      <Route path="/events" element={<Events />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/events-detail" element={<EventsDetail />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/privacy-terms" element={<PrivacyPolicyTermsService />} />
    </Routes>
    </>
  );
}

export default App;