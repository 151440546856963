import React from 'react';
import { RiFlashlightLine } from "react-icons/ri";

const IconBox = (props) => {

    return (
        <>
            <div className="mx-auto bg-white border-2 border-[#E8E8E8] rounded-xl p-4">
                {/* Icon and Heading Section (Horizontally Aligned) */}
                <div className="flex items-center mb-3">
                {/* Icon */}
                <div className="bg-[#293762] text-white rounded-full p-3 mr-2 text-[20px]">
                    {/* Replace this with your icon (using Font Awesome, Heroicons, etc.) */}
                    <RiFlashlightLine />
                </div>
                
                {/* Heading */}
                <h3 className="text-[16px] font-bold font-sans">{props.title}</h3>
                </div>

                {/* Paragraph Section */}
                <p className="text-[#1A191E] font-medium">
                {props.para}
                </p>
            </div>
        </>
    )
}

export default IconBox;

