import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('Pathname changed to:', pathname); // Logs current path
    window.scrollTo(0, 0); // Scroll to the top
  }, [pathname]);

  return null; // No need to render anything
};

export default ScrollToTop;
