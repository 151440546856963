import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { GoCheckCircleFill } from "react-icons/go";
import axios from 'axios';
import Loader from '../Loader';

const ComingSoon = () => {
    const disableContextMenu = (e) => {
        e.preventDefault();
        };
    
        const [submitted, setSubmitted] = useState(false);
        const [email, setEmail] = useState(''); // State to hold the email input
        const [error, setError] = useState(null); // State to hold error messages
        const [apiIsLoading, setapiIsLoading] = useState(false);
    
        const handleSubmit = async (e) => { 
            e.preventDefault(); // prevent page reload
           
    
            try {
                setapiIsLoading(true)
                const response = await axios.post('https://castme.productions/admin/api/mailchimp_subscribe', {
                    email: email // Send the email to the API
                });
    
                if (response.data.status) {
                    setapiIsLoading(false)
                    setSubmitted(true); // Show success message
                    setError(null); // Reset any previous error
                } else {
                    setapiIsLoading(false)
                    setError(response.data.message); // Set error message
                }
            } catch (err) {
                setapiIsLoading(false)
                console.error('Error subscribing:', err);
                setError('Subscription failed. Please try again.'); // Set error message for network issues
            } 
        };

    return (
        <>
         <Loader isLoading={apiIsLoading}/>
            <title>Coming Soon</title>
            <section className="bg-[#0E1E54] py-10 md:py-20 text-center px-[0px] md:px1-[50px] lg:px1-[100px] h-[100%]  flex items-center lg:h-screen custom-height-c">
                <div className="container mx-auto px-4 lg:px-0 max-w-[68rem] px-4 sm:px1-6 lg:px1-8 lg:w-[1111px]">
                    <div className='flex items-center justify-center mb-[25px]'>
                        <NavLink to="/">
                            {/* <img src="/images/whitelogo.svg" alt="logo" className='w-[270px] md:w1-[400px] md:w-[448px] md:h-[151px]' onContextMenu={disableContextMenu} /> */}
                            <img src="/images/castme_logo_sufiyan.svg" alt="logo" className='w-[270px] md:w-[400px] md:h-[151px]' onContextMenu={disableContextMenu} />
                        </NavLink>
                    </div>

                    <h2 className="text-text-secondry text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb1-6 mb-[44px] md:mb-[40px] font-sans text-center tracking-[-2px] leading-[48px] md:leading-[60px]">
                        Coming Soon!
                    </h2>

                    <p className="lg:mx-8 lg:text-[20px]  text-white text-[16px] md:text-[18px] mb1-5 mb-[35px] md:mb-[50px] mx-2 md:mx1-12 lg:mx1-28 leading-[28px] md:leading-[32px] ">
                        Early Access members will receive free subscriptions and be eligible for exclusive Cast Me events and shows! Whether you're an actor seeking trusted auditions, a writer looking for funding, or a casting director in need of cutting-edge technology, Cast Me connects you to a world of high-value opportunities.
                    </p>
                    <NavLink to="/">
                        <button className="bg-transparent border border-[#FFC727] text-[#FFC727] py-3 px-8 rounded-[12px] hover:bg-[#FFC727] hover:text-[#08145E] transition duration-300 mb1-12 mb-[44px] md:mb-[55px] mt1-4 md:mb1-[4rem] md:mt1-[3rem] text-[16px]">
                            Learn More
                        </button>
                    </NavLink>

                    {/* <div className="flex flex-col max-w-[728px] mx-auto mb-[42px] lg:mb-[20px] ">
                        {!submitted ? (
                            <form className="w-full mx-auto px-6 rounded-lg " onSubmit={handleSubmit}>
                                <label className="block text-[18px] font-semibold text-white mb-4 font-sans text-center md:text-left" htmlFor="email">
                                    Join the Cast Me Community  
                                </label>
                                <div className='flex flex-col md:flex-row items-center'>
                                    <div className="flex-grow mb-4 md:mb-0 mr-0 md:mr-2 w-full md:w-[112px] lg:w-[112px]">
                                        <input
                                            type="email"
                                            id="email"
                                            className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none"
                                            placeholder="Enter your email address"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="bg-[#FFC727] text-[#08145E] py-4 px-4 rounded-lg font-semibold hover:bg-[#08145E] transition duration-300 w-full md:w-[112px] lg:w-[112px] border border-[#FFC727] hover:text-[#FFC727] hover:bg-transparent hover:border hover:border-[#FFC727]"
                                    >
                                        Join
                                    </button>
                                </div>
                                {error && <p className="text-red-500 mt-2">{error}</p>}
                            </form>
                        ) : (
                            <div className="md:mb-[72px] text-white text-[20px] md:text-[20px] font-semibold flex items-center text-center justify-center  mt-[-12px]">
                                <GoCheckCircleFill className='text-[30px] text-text-secondry' />
                                <span className='ml-3 leading-tight max-w-[170px] md:max-w-[100%]'>Thanks for joining our community!</span>
                            </div>
                        )}
                    </div> */}
                    <div className="flex flex-col max-w-[728px] mx-auto mb-[42px] lg:mb-[20px]">
  {!submitted ? (
    <form
      className="w-full mx-auto px-6 rounded-lg"
      onSubmit={handleSubmit}
    >
      <label
        className="block text-[18px] font-semibold text-white mb-4 font-sans text-center md:text-left"
        htmlFor="email"
      >
        Join the Cast Me Community
      </label>
      <div className="flex flex-col md:flex-row items-center lg:flex-row">
        <div className="flex-grow mb-4 md:mb-0 lg:mb-0 lg:mr-2 w-full lg:w-[626px]">
          <input
            type="email"
            id="email"
            className="w-full h-[58px] px-4 border border-gray-300 rounded-lg focus:outline-none"
            placeholder="Enter your email address"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="bg-[#FFC727] text-[#08145E] py-4 px-4 lg:py-0 lg:h-[58px] rounded-lg font-semibold hover:bg-[#08145E] transition duration-300 w-full md:w-[112px] lg:w-[112px] lg:ml-2 border border-[#FFC727] hover:text-[#FFC727] hover:bg-transparent hover:border hover:border-[#FFC727]"
        >
          Join
        </button>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  ) : (
    <div className="md:mb-[72px] text-white text-[20px] md:text-[20px] font-semibold flex items-center text-center justify-center mt-[-12px]">
      <GoCheckCircleFill className="text-[30px] text-text-secondry" />
      <span className="ml-3 leading-tight max-w-[170px] md:max-w-[100%]">
        Thanks for joining our community!
      </span>
    </div>
  )}
</div>


                    <div className="flex justify-center mt1-[18px] md:mt1-[18px] space-x-4">
                        <NavLink
                            to="https://www.instagram.com/castmeapp?igsh=MXUwcHhhbnRvMGp2Nw=="
                            className="text-gray-500 hover:text-pink-600 transition-all duration-300 ease-in-out" target='_blank'
                        >
                            <FaInstagram className='text-[24px] text-text-secondry' />
                        </NavLink>
                        <NavLink
                            to="https://tiktok.com"
                            className="text-gray-500 hover:text-blue-500 transition-all duration-300 ease-in-out" target='_blank'
                        >
                            <FaTiktok className='text-[24px] text-text-secondry' />
                        </NavLink>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ComingSoon;
